<template>
    <div class="loader-container">
        <div class="loader"></div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "AccountEmailUpdateComplete",
    data() {
        return {
            isValidated: false,
            newEmail: ''
        };
    },
    async created() {
        this.updateFromRoute();
    },
    async beforeRouteUpdate(to, from, next) {
        this.updateFromRoute();
        next();
    },
    async mounted() {
        this.closePage();
    },
    methods: {
        ...mapActions("users", ["sendEmailConfirmation"]),
        updateFromRoute() {
            let parameters = this.$route.query;
            if (parameters["email"]) {
                this.newEmail = parameters["email"].replace(" ", "+");
            }
            if (parameters["validated"]) {
                this.isValidated = parameters["validated"] === "true";
                // console.log(this.isValidated)
            }
        },
        async closePage() {
            if(this.isValidated == false) {
                // console.log('false', this.newEmail);
                window.location.href = `/account_email_update?email=${this.newEmail}&validated=${this.isValidated}`;
            } else {
                // Do not patch new email from a simple query without any password confirmation. 
                // email patch is done in the native part of the App which checked the validity of the password.
                window.location.href = '/account_email_sent/';
                // try {
                //     // console.log(this.newEmail);
                //     let response = await this.sendEmailConfirmation({ username: this.newEmail });
                // } finally {
                //     window.location.href = '/account_email_sent/';
                // }
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.loader-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader {
    margin: auto;
    border: 1em solid #D9D9D9;
    border-top: 1em solid #3579f6;
    border-radius: 50%;
    width: 8em;
    height: 8em;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
